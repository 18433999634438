import AppRoutes from 'AppRoutes';
import classNames from 'classnames';
import AppNavbar from 'components/AppNavbar';
import Spinner from 'components/Spinner';
import { TestIds } from 'models/enums/test-ids.enum';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { selectIsLoading } from 'store/app/AppSelectors';
import { setLanguage } from 'store/app/AppSlice';
import { selectIsLoggedIn } from 'store/auth/AuthSelectors';
import './App.scss';

const App: React.FC = () => {
  const loading = useSelector(selectIsLoading);
  const isAuthenticated = useSelector(selectIsLoggedIn);

  const dispatch = useDispatch();
  const _setLanguage = () => dispatch(setLanguage(language));

  const { i18n } = useTranslation();
  const { language } = i18n;

  // Keeps the store in sync with the defaultLanguage from i18next
  useEffect(() => {
    _setLanguage();
  }, [language]);

  return (
    <div
      className={classNames('app-container', { public: !isAuthenticated })}
      data-testid={TestIds.APP_CONTAINER}>
      <AppRoutes>
        {loading && <Spinner />}
        <ToastContainer autoClose={8000} />
        {isAuthenticated && <AppNavbar />}
      </AppRoutes>
    </div>
  );
};

export default App;
