import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginatedResponse } from 'models/common/paginated-response.interface';
import { SubArticle } from 'models/sub-articles/sub-article.class';

interface SubArticlesState {
  all: PaginatedResponse<SubArticle> | null;
  selected: SubArticle | null;
  loading: boolean;
}

export const initialState: SubArticlesState = {
  all: null,
  selected: null,
  loading: false,
};

export const slice = createSlice({
  name: 'subArticles',
  initialState,
  reducers: {
    fetchSubArticlesSuccess: (state, action: PayloadAction<PaginatedResponse<SubArticle>>) => {
      state.all = action.payload;
      state.loading = false;
    },

    fetchSubArticleSuccess: (state, action: PayloadAction<SubArticle>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveSubArticleStart: state => {
      state.loading = true;
    },
    saveSubArticleSuccess: (state, action: PayloadAction<SubArticle>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveCustomSubArticleSuccess: state => {
      state.loading = false;
    },
    saveSubArticleError: state => {
      state.loading = false;
    },
    resetSubArticlesState: state => {
      state.loading = false;
      state.selected = null;
    },
  },
});
export const {
  fetchSubArticlesSuccess,
  fetchSubArticleSuccess,
  saveSubArticleStart,
  saveSubArticleSuccess,
  saveSubArticleError,
  saveCustomSubArticleSuccess,
  resetSubArticlesState,
} = slice.actions;

export default slice.reducer;
