import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import AppLogo from 'components/AppLogo';
import { useViewport, Viewport } from 'hooks/use-viewport';
import { NavbarItem } from 'models/common/navbar-item.interface';
import { RoutePaths } from 'models/enums/route-paths.enum';
import { TestIds } from 'models/enums/test-ids.enum';
import { UserRole } from 'models/enums/user-role.enum';
import React, { useEffect, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectNavbarCollapsed } from 'store/app/AppSelectors';
import { setNavbarCollapsed } from 'store/app/AppSlice';
import { selectAuthenticatedUser } from 'store/auth/AuthSelectors';
import { logOut } from 'store/auth/AuthSlice';
import AppNavbarItem from './AppNavbarItem';

const AppNavbar: React.FC = () => {
  const currentUser = useSelector(selectAuthenticatedUser);
  const navbarCollapsed = useSelector(selectNavbarCollapsed);

  const dispatch = useDispatch();
  const _logOut = () => dispatch(logOut());
  const _setNavbarCollapsed = (collapsed: boolean) => dispatch(setNavbarCollapsed(collapsed));

  const [navbarItems, setNavbarItems] = useState<NavbarItem[]>([]);

  const { t } = useTranslation();
  const { viewport } = useViewport();
  const { pathname } = useLocation();

  const userName = navbarCollapsed
    ? `${currentUser?.firstName.charAt(0).toUpperCase()}${currentUser?.lastName
        .charAt(0)
        .toUpperCase()}`
    : `${currentUser?.firstName} ${currentUser?.lastName}`;

  const userNavbarItems: NavbarItem[] = [
    {
      title: t('navbar.confirmations.title'),
      icon: ['far', 'barcode'],
      children: [
        {
          title: t('navbar.confirmations.orders'),
          icon: ['far', 'th-list'],
          to: RoutePaths.CONFIRMATIONS_OVERVIEW,
        },
      ],
    },
    {
      title: t('navbar.projects.title'),
      icon: ['far', 'folder-open'],
      children: [
        {
          title: t('navbar.projects.documents'),
          icon: ['far', 'file-alt'],
          to: RoutePaths.PROJECT_DOCUMENTS,
        },
      ],
    },
  ];

  const adminNavbarItem: NavbarItem = {
    title: t('navbar.admin.title'),
    icon: ['far', 'user-cog'],
    children: [
      {
        title: t('navbar.admin.users'),
        icon: ['far', 'users'],
        to: RoutePaths.USERS,
      },
      {
        title: t('navbar.admin.articles'),
        icon: ['far', 'pencil-ruler'],
        to: RoutePaths.ARTICLES,
      },
      {
        title: t('navbar.admin.subArticles'),
        icon: ['far', 'cubes'],
        to: RoutePaths.SUB_ARTICLES,
      },
      {
        title: t('navbar.admin.alterations'),
        icon: ['far', 'sliders-h'],
        to: RoutePaths.ALTERATIONS,
      },
      {
        title: t('navbar.admin.sawArticles'),
        icon: ['far', 'crop'],
        to: RoutePaths.SAW_ARTICLES,
      },
      {
        title: t('navbar.admin.locations'),
        icon: ['far', 'map-marked-alt'],
        to: RoutePaths.LOCATIONS,
      },
      {
        title: t('navbar.admin.descriptions'),
        icon: ['far', 'comment-lines'],
        to: RoutePaths.DESCRIPTIONS,
      },
      {
        title: t('navbar.admin.grips'),
        icon: ['far', 'cabinet-filing'],
        to: RoutePaths.GRIPS,
      },
      {
        title: t('navbar.admin.colors'),
        icon: ['far', 'palette'],
        to: RoutePaths.COLORS,
      },
      {
        title: t('navbar.admin.edgeColors'),
        icon: ['far', 'swatchbook'],
        to: RoutePaths.EDGECOLORS,
      },
      {
        title: t('navbar.admin.models'),
        icon: ['far', 'layer-group'],
        to: RoutePaths.MODELS,
      },
      {
        title: t('navbar.admin.catalogs'),
        icon: ['far', 'book-open'],
        to: RoutePaths.CATALOGS,
      },
    ],
  };

  const handleToggleItemCollapse = (item: NavbarItem) => {
    setNavbarItems([
      ...navbarItems.map(navItem => {
        if (navItem.title === item.title) {
          navItem.expanded = !navItem.expanded;
        } else {
          navItem.expanded = false;
        }
        return navItem;
      }),
    ]);
  };

  useEffect(() => {
    if (viewport.device !== Viewport.DESKTOP) {
      _setNavbarCollapsed(false);
    }
  }, [viewport.device]);

  useEffect(() => {
    let navItems = [...userNavbarItems];

    if (currentUser?.role === UserRole.ADMIN) {
      navItems = [...navItems, adminNavbarItem];
    }

    navItems.forEach(item => {
      item.expanded = childRouteIsActive(item, pathname);
      return item;
    });

    setNavbarItems(navItems);
  }, [currentUser?.role]);

  return (
    <Navbar
      expand="lg"
      variant="light"
      className={classNames('navbar-vertical fixed-left', {
        'navbar-vertical-sm': navbarCollapsed,
      })}
      data-testid={TestIds.APP_NAVBAR}>
      <div className="container-fluid">
        {/* Mobile toggler */}
        <Navbar.Toggle aria-controls="appNavbarCollapse" />
        {/* Brand */}
        <Navbar.Brand>
          <AppLogo />
        </Navbar.Brand>

        {/* Navbar body */}
        <Navbar.Collapse id="appNavbarCollapse" className="mt-3">
          <Nav as="ul">
            {navbarItems.map(item => {
              return (
                <AppNavbarItem
                  item={item}
                  key={item.title}
                  navbarCollapsed={navbarCollapsed}
                  onToggleItemCollapse={() => handleToggleItemCollapse(item)}
                />
              );
            })}
          </Nav>

          <hr className="navbar-divider my-3" />

          <div className="mt-auto pb-0 pb-lg-3 text-center flex-column">
            <h2 className="pb-3 m-0">
              <span
                className="badge badge-pill badge-soft-primary"
                data-testid={TestIds.APP_NAVBAR_USERNAME}>
                {userName}
              </span>
            </h2>
            <button
              className="btn btn-outline-danger btn-sm"
              onClick={() => _logOut()}
              data-testid={TestIds.APP_NAVBAR_LOGOUT_BTN}>
              {!navbarCollapsed && t('navbar.signOut')}
              <FontAwesomeIcon
                icon={'sign-out-alt'}
                className={classNames({ 'ml-2': !navbarCollapsed })}
              />
            </button>
          </div>
        </Navbar.Collapse>

        {/* Toggle Navbar Collapsed Btn */}
        <button
          className="btn btn-white btn-rounded btn-collapse d-none d-lg-inline-block"
          onClick={() => _setNavbarCollapsed(!navbarCollapsed)}
          data-testid={TestIds.APP_NAVBAR_TOGGLE_NAVBAR_SIZE}>
          <FontAwesomeIcon icon={'chevron-left'} className="icon" />
        </button>
      </div>
    </Navbar>
  );
};

const childRouteIsActive = (parentItem: NavbarItem, pathname: string) => {
  const result = parentItem.children?.find(child => child.to && child.to === pathname);
  return !!result;
};

export default AppNavbar;
