import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBadgePercent,
  faBarcode,
  faBookOpen,
  faCabinetFiling,
  faCalendar,
  faCommentLines,
  faCrop,
  faCubes,
  faFileAlt,
  faFilter,
  faFolderOpen,
  faIndustry,
  faLayerGroup,
  faLink,
  faMapMarkedAlt,
  faPalette,
  faPen,
  faPencilRuler,
  faSlidersH,
  faSwatchbook,
  faThList,
  faUnlink,
  faUserCog,
  faUsers,
  faHome,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowLeft,
  faArrowRight,
  faCheck,
  faCheckCircle,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCopy,
  faDolly,
  faDonate,
  faDownload,
  faEdit,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFilePdf,
  faInfoCircle,
  faInfoSquare,
  faPlus,
  faPrint,
  faSave,
  faSearch,
  faSignOutAlt,
  faSpinner,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faTrashUndoAlt,
  faHouse,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  faEye,
  faEyeSlash,
  faFolderOpen,
  faSpinner,
  faInfoSquare,
  faInfoCircle,
  faExclamationCircle,
  faTimesCircle,
  faCheck,
  faCheckCircle,
  faSignOutAlt,
  faUserCog,
  faUsers,
  faFileAlt,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faArrowLeft,
  faArrowRight,
  faPlus,
  faEdit,
  faTrashAlt,
  faTrashUndoAlt,
  faSearch,
  faSave,
  faPencilRuler,
  faMapMarkedAlt,
  faBadgePercent,
  faCommentLines,
  faCabinetFiling,
  faSwatchbook,
  faLayerGroup,
  faPalette,
  faBookOpen,
  faDonate,
  faDownload,
  faDolly,
  faCopy,
  faPen,
  faLink,
  faUnlink,
  faCubes,
  faSlidersH,
  faCrop,
  faBarcode,
  faChevronUp,
  faCalendar,
  faCircle,
  faChevronCircleLeft,
  faChevronCircleRight,
  faTimes,
  faThList,
  faFilePdf,
  faPrint,
  faFilter,
  faIndustry,
  faHouse,
  faHome,
);
