import { ApiErrorMessage } from 'models/enums/api-error-message.enum';

export function apiErrorMessageToTranslationKey(messageString: ApiErrorMessage | string): string {
  const errorMessagesKey = 'errorMessages';

  switch (messageString) {
    case ApiErrorMessage.UserNotFound:
    case ApiErrorMessage.WrongPassword:
      return `${errorMessagesKey}.loginMismatch`;

    case ApiErrorMessage.EmailNotFound:
      return `${errorMessagesKey}.emailNotFound`;

    case ApiErrorMessage.TokenNotFound:
      return `${errorMessagesKey}.tokenNotFound`;

    case ApiErrorMessage.UserExists:
    case ApiErrorMessage.EmailAlreadyInUse:
      return `${errorMessagesKey}.userExists`;
    case ApiErrorMessage.TokenExpiredError:
      return `${errorMessagesKey}.tokenExpired`;
    default:
      return `${errorMessagesKey}.defaultError`;
  }
}
