import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { FetchAllParams } from 'models/common/fetch-all-params.interface';
import { PaginatedResponse } from 'models/common/paginated-response.interface';
import { ResultsAndParams } from 'models/common/results-and-params.interface';
import { ConfirmedOrder } from 'models/confirmed-orders/confirmed-order.class';
import { OrderArticle } from 'models/confirmed-orders/order-article.class';

interface ConfirmedOrdersState {
  all: PaginatedResponse<ConfirmedOrder> | null;
  expandedConfirmedOrders: ConfirmedOrder[];
  printConfirmedOrders: ConfirmedOrder[];
  printConfirmedOrdersIsLoading: boolean;
  selected: ConfirmedOrder | null;
  confirmedOrdersIsLoading: boolean;
  orderArticlesIsLoading: boolean;
  selectedOrderArticle: OrderArticle | null;
  confirmedOrderIsUpdating: boolean;
  latestOrderParams: FetchAllParams;
  latestArticleParams: FetchAllParams;
}

export const initialState: ConfirmedOrdersState = {
  all: null,
  expandedConfirmedOrders: [],
  printConfirmedOrders: [],
  printConfirmedOrdersIsLoading: false,
  selected: null,
  selectedOrderArticle: null,
  confirmedOrdersIsLoading: false,
  orderArticlesIsLoading: false,
  confirmedOrderIsUpdating: false,
  latestOrderParams: {},
  latestArticleParams: {},
};

export const slice = createSlice({
  name: 'confirmedOrders',
  initialState,
  reducers: {
    fetchConfirmedOrdersStart: (state, params: PayloadAction<FetchAllParams>) => {
      state.latestOrderParams = params.payload;
      state.confirmedOrdersIsLoading = true;
    },
    fetchConfirmedOrdersSuccess: (
      state,
      action: PayloadAction<ResultsAndParams<PaginatedResponse<ConfirmedOrder>>>,
    ) => {
      if (action.payload.params === current(state.latestOrderParams)) {
        state.all = action.payload.results;
        state.confirmedOrdersIsLoading = false;
      }
    },
    fetchConfirmedOrdersError: state => {
      state.confirmedOrdersIsLoading = false;
    },
    saveConfirmedOrderStart: state => {
      state.confirmedOrdersIsLoading = true;
    },
    saveConfirmedOrderSuccess: (state, action: PayloadAction<ConfirmedOrder>) => {
      state.selected = action.payload;
      state.confirmedOrdersIsLoading = false;
    },
    saveCustomConfirmedOrderSuccess: state => {
      state.confirmedOrdersIsLoading = false;
    },
    saveConfirmedOrderError: state => {
      state.confirmedOrdersIsLoading = false;
    },
    resetConfirmedOrdersState: state => {
      state.confirmedOrdersIsLoading = false;
      state.selected = null;
    },
    fetchOrderArticlesStart: (state, params: PayloadAction<FetchAllParams>) => {
      state.latestArticleParams = params.payload;
      state.orderArticlesIsLoading = true;
    },
    fetchOrderArticlesSuccess: (state, action: PayloadAction<ResultsAndParams<ConfirmedOrder>>) => {
      if (action.payload.params === current(state.latestArticleParams)) {
        state.selected = action.payload.results;
        const index = state.expandedConfirmedOrders.findIndex(
          co => co.Referentie === action.payload.results.Referentie,
        );

        if (index === -1) {
          state.expandedConfirmedOrders = [
            ...state.expandedConfirmedOrders,
            action.payload.results,
          ];
        } else {
          const replacedOrders = state.expandedConfirmedOrders.filter(
            co => co.Referentie !== action.payload.results.Referentie,
          );
          state.expandedConfirmedOrders = [...replacedOrders, action.payload.results];
        }
        state.orderArticlesIsLoading = false;
      }
    },
    fetchOrderArticlesError: state => {
      state.orderArticlesIsLoading = false;
    },
    fetchPrintOrdersStart: state => {
      state.printConfirmedOrdersIsLoading = true;
    },
    fetchPrintOrdersSuccess: (state, action: PayloadAction<ConfirmedOrder[]>) => {
      state.printConfirmedOrders = action.payload;
      state.printConfirmedOrdersIsLoading = false;
    },
    fetchPrintOrdersError: state => {
      state.printConfirmedOrdersIsLoading = false;
    },
    updateOrderArticleStart: state => {
      state.orderArticlesIsLoading = true;
    },
    updateOrderArticleSuccess: (state, action: PayloadAction<OrderArticle>) => {
      state.selectedOrderArticle = action.payload;
      state.orderArticlesIsLoading = false;
    },
    updateOrderArticleError: state => {
      state.orderArticlesIsLoading = false;
    },
    fetchUpdatedConfirmedOrderStart: state => {
      state.confirmedOrderIsUpdating = true;
    },
    fetchUpdatedConfirmedOrderSuccess: (state, action: PayloadAction<ConfirmedOrder>) => {
      if (state.all && state.all.docs) {
        state.all.docs = state.all.docs.map(ord => {
          if (ord.Referentie === action.payload.Referentie) {
            return { ...ord, Afgehandeld: action.payload.Afgehandeld };
          }
          return ord;
        });
      }
    },
    fetchUpdatedConfirmedOrderError: state => {
      state.confirmedOrderIsUpdating = false;
    },
  },
});
export const {
  fetchConfirmedOrdersStart,
  fetchConfirmedOrdersSuccess,
  fetchConfirmedOrdersError,
  saveConfirmedOrderStart,
  saveConfirmedOrderSuccess,
  saveConfirmedOrderError,
  saveCustomConfirmedOrderSuccess,
  resetConfirmedOrdersState,
  fetchOrderArticlesStart,
  fetchOrderArticlesSuccess,
  fetchOrderArticlesError,
  fetchPrintOrdersStart,
  fetchPrintOrdersSuccess,
  fetchPrintOrdersError,
  updateOrderArticleError,
  updateOrderArticleSuccess,
  updateOrderArticleStart,
  fetchUpdatedConfirmedOrderStart,
  fetchUpdatedConfirmedOrderSuccess,
  fetchUpdatedConfirmedOrderError,
} = slice.actions;

export default slice.reducer;
