import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginatedResponse } from 'models/common/paginated-response.interface';
import { SawArticle } from 'models/saw-articles/saw-article.class';

interface SawArticlesState {
  all: PaginatedResponse<SawArticle> | null;
  selected: SawArticle | null;
  loading: boolean;
}

export const initialState: SawArticlesState = {
  all: null,
  selected: null,
  loading: false,
};

export const slice = createSlice({
  name: 'sawArticles',
  initialState,
  reducers: {
    fetchSawArticlesStart: state => {
      state.loading = true;
    },
    fetchSawArticlesSuccess: (state, action: PayloadAction<PaginatedResponse<SawArticle>>) => {
      state.all = action.payload;
      state.loading = false;
    },
    fetchSawArticlesError: state => {
      state.loading = false;
    },
    fetchSawArticleSuccess: (state, action: PayloadAction<SawArticle>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveSawArticleStart: state => {
      state.loading = true;
    },
    saveSawArticleSuccess: (state, action: PayloadAction<SawArticle>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveCustomSawArticleSuccess: state => {
      state.loading = false;
    },
    saveSawArticleError: state => {
      state.loading = false;
    },
    resetSawArticlesState: state => {
      state.loading = false;
      state.selected = null;
    },
  },
});
export const {
  fetchSawArticlesStart,
  fetchSawArticlesSuccess,
  fetchSawArticlesError,
  fetchSawArticleSuccess,
  saveSawArticleStart,
  saveSawArticleSuccess,
  saveSawArticleError,
  saveCustomSawArticleSuccess,
  resetSawArticlesState,
} = slice.actions;

export default slice.reducer;
