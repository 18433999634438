import { AppRoute } from 'models/common/app-route.interface';
import { Pages } from 'models/enums/pages.enum';
import { RoutePaths } from 'models/enums/route-paths.enum';
import { lazy } from 'react';

const importComponent = (name: Pages) => lazy(() => import(`pages/${name}`));

const ROUTES: AppRoute[] = [
  {
    path: '/',
    key: 'ROOT',
    component: importComponent(Pages.Home),
    exact: true,
  },
  {
    path: RoutePaths.SIGN_IN,
    key: 'SIGN_IN',
    component: importComponent(Pages.SignIn),
    isPublic: true,
  },
  {
    path: `${RoutePaths.PASSWORD_CREATE}/:token`,
    key: 'PASSWORD_CREATE',
    component: importComponent(Pages.PasswordReset),
    isPublic: true,
  },
  {
    path: `${RoutePaths.PASSWORD_RESET}/:token`,
    key: 'PASSWORD_RESET',
    component: importComponent(Pages.PasswordReset),
    isPublic: true,
  },
  {
    path: RoutePaths.PASSWORD_RESET,
    key: 'PASSWORD_RESET_REQUEST',
    component: importComponent(Pages.PasswordResetRequest),
    isPublic: true,
  },
  {
    path: RoutePaths.USERS,
    key: 'USERS',
    component: importComponent(Pages.Users),
    isPublic: false,
    exact: true,
  },
  {
    path: `${RoutePaths.USERS}/:userId`,
    key: 'USERS_EDIT',
    component: importComponent(Pages.UserAddEdit),
    isPublic: false,
  },
  {
    path: `${RoutePaths.USERS}/add`,
    key: 'USERS_ADD',
    component: importComponent(Pages.UserAddEdit),
    isPublic: false,
  },
  {
    path: RoutePaths.ARTICLES,
    key: 'ARTICLES',
    component: importComponent(Pages.Articles),
    isPublic: false,
    exact: true,
  },
  {
    path: `${RoutePaths.ARTICLES}/:articleId`,
    key: 'ARTICLES_EDIT',
    component: importComponent(Pages.ArticleAddEdit),
    isPublic: false,
  },
  {
    path: `${RoutePaths.ARTICLES}/add`,
    key: 'ARTICLES_ADD',
    component: importComponent(Pages.ArticleAddEdit),
    isPublic: false,
  },
  {
    path: RoutePaths.SUB_ARTICLES,
    key: 'SUB_ARTICLES',
    component: importComponent(Pages.SubArticles),
    isPublic: false,
    exact: true,
  },
  {
    path: `${RoutePaths.SUB_ARTICLES}/:subArticleId`,
    key: 'SUB_ARTICLES_EDIT',
    component: importComponent(Pages.SubArticleAddEdit),
    isPublic: false,
  },
  {
    path: `${RoutePaths.SUB_ARTICLES}/add`,
    key: 'SUB_ARTICLES_ADD',
    component: importComponent(Pages.SubArticleAddEdit),
    isPublic: false,
  },
  {
    path: RoutePaths.ALTERATIONS,
    key: 'ALTERATIONS',
    component: importComponent(Pages.Alterations),
    isPublic: false,
    exact: true,
  },
  {
    path: `${RoutePaths.ALTERATIONS}/:alterationId`,
    key: 'ALTERATIONS_EDIT',
    component: importComponent(Pages.AlterationAddEdit),
    isPublic: false,
  },
  {
    path: `${RoutePaths.ALTERATIONS}/add`,
    key: 'ALTERATIONS_ADD',
    component: importComponent(Pages.AlterationAddEdit),
    isPublic: false,
  },
  {
    path: RoutePaths.SAW_ARTICLES,
    key: 'SAW_ARTICLES',
    component: importComponent(Pages.SawArticles),
    isPublic: false,
    exact: true,
  },
  {
    path: `${RoutePaths.SAW_ARTICLES}/:sawArticleId`,
    key: 'SAW_ARTICLES_EDIT',
    component: importComponent(Pages.SawArticleAddEdit),
    isPublic: false,
  },
  {
    path: `${RoutePaths.SAW_ARTICLES}/add`,
    key: 'SAW_ARTICLES_ADD',
    component: importComponent(Pages.SawArticleAddEdit),
    isPublic: false,
  },
  {
    path: RoutePaths.LOCATIONS,
    key: 'LOCATIONS',
    component: importComponent(Pages.Locations),
    isPublic: false,
    exact: true,
  },
  {
    path: `${RoutePaths.LOCATIONS}/:locationId`,
    key: 'LOCATIONS_EDIT',
    component: importComponent(Pages.LocationAddEdit),
    isPublic: false,
  },
  {
    path: `${RoutePaths.LOCATIONS}/add`,
    key: 'LOCATIONS_ADD',
    component: importComponent(Pages.LocationAddEdit),
    isPublic: false,
  },
  {
    path: RoutePaths.PROJECT_DOCUMENTS,
    key: 'PROJECT_DOCUMENTS',
    component: importComponent(Pages.ProjectDocuments),
    isPublic: false,
    exact: true,
  },
  {
    path: RoutePaths.CONFIRMATIONS_OVERVIEW,
    key: 'CONFIRMATIONS_OVERVIEW',
    component: importComponent(Pages.ConfirmationsOverview),
    isPublic: false,
    exact: true,
  },
  {
    path: `${RoutePaths.OFFER_DETAIL}/:offerId`,
    key: 'OFFER_DETAIL',
    component: importComponent(Pages.OfferDetail),
    isPublic: false,
  },
  {
    path: RoutePaths.DESCRIPTIONS,
    key: 'DESCRIPTIONS',
    component: importComponent(Pages.Descriptions),
    isPublic: false,
    exact: true,
  },
  {
    path: `${RoutePaths.DESCRIPTIONS}/:descriptionId`,
    key: 'DESCRIPTIONS_EDIT',
    component: importComponent(Pages.DescriptionAddEdit),
    isPublic: false,
  },
  {
    path: `${RoutePaths.DESCRIPTIONS}/add`,
    key: 'DESCRIPTIONS_ADD',
    component: importComponent(Pages.DescriptionAddEdit),
    isPublic: false,
  },
  {
    path: RoutePaths.GRIPS,
    key: 'GRIPS',
    component: importComponent(Pages.Grips),
    isPublic: false,
    exact: true,
  },
  {
    path: `${RoutePaths.GRIPS}/:gripId`,
    key: 'GRIPS_EDIT',
    component: importComponent(Pages.GripAddEdit),
    isPublic: false,
  },
  {
    path: `${RoutePaths.GRIPS}/add`,
    key: 'GRIPS_ADD',
    component: importComponent(Pages.GripAddEdit),
    isPublic: false,
  },
  {
    path: RoutePaths.EDGECOLORS,
    key: 'EDGECOLORS',
    component: importComponent(Pages.EdgeColors),
    isPublic: false,
    exact: true,
  },
  {
    path: `${RoutePaths.EDGECOLORS}/:edgeColorId`,
    key: 'EDGECOLORS_EDIT',
    component: importComponent(Pages.EdgeColorAddEdit),
    isPublic: false,
  },
  {
    path: `${RoutePaths.EDGECOLORS}/add`,
    key: 'EDGECOLORS_ADD',
    component: importComponent(Pages.EdgeColorAddEdit),
    isPublic: false,
  },

  {
    path: RoutePaths.COLORS,
    key: 'COLORS',
    component: importComponent(Pages.Colors),
    isPublic: false,
    exact: true,
  },
  {
    path: `${RoutePaths.COLORS}/:colorId`,
    key: 'COLORS_EDIT',
    component: importComponent(Pages.ColorAddEdit),
    isPublic: false,
  },
  {
    path: `${RoutePaths.COLORS}/add`,
    key: 'COLORS_ADD',
    component: importComponent(Pages.ColorAddEdit),
    isPublic: false,
  },
  {
    path: RoutePaths.MODELS,
    key: 'MODELS',
    component: importComponent(Pages.Models),
    isPublic: false,
    exact: true,
  },
  {
    path: `${RoutePaths.MODELS}/:modelId`,
    key: 'MODELS_EDIT',
    component: importComponent(Pages.ModelsAddEdit),
    isPublic: false,
  },
  {
    path: `${RoutePaths.MODELS}/add`,
    key: 'MODELS_ADD',
    component: importComponent(Pages.ModelsAddEdit),
    isPublic: false,
  },
  {
    path: RoutePaths.CATALOGS,
    key: 'CATALOGS',
    component: importComponent(Pages.Catalogs),
    isPublic: false,
    exact: true,
  },
  {
    path: `${RoutePaths.CATALOGS}/:catalogId`,
    key: 'CATALOGS_EDIT',
    component: importComponent(Pages.CatalogAddEdit),
    isPublic: false,
  },
  {
    path: `${RoutePaths.CATALOGS}/add`,
    key: 'CATALOGS_ADD',
    component: importComponent(Pages.CatalogAddEdit),
    isPublic: false,
  },
  {
    path: `${RoutePaths.PRINT_OFFER_DETAIL}/:offerId`,
    key: 'PRINT_OFFER',
    component: importComponent(Pages.PrintOffer),
    isPublic: false,
  },
];

export default ROUTES;
