import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastOptions } from 'models/common/toast-options.interface';
import { LocalStorageKeys } from 'models/enums/local-storage-keys.enum';
import { toast } from 'react-toastify';
import { toastOptionsToContent } from 'utils/toast-options-to-content';

interface AppState {
  loading: boolean;
  language: string;
  navbarCollapsed: boolean;
}

export const initialState: AppState = {
  loading: false,
  language: localStorage.getItem(LocalStorageKeys.LANGUAGE) || 'nl',
  navbarCollapsed: JSON.parse(localStorage.getItem(LocalStorageKeys.NAVBAR_COLLAPSED) || 'false'),
};

export const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoading: state => {
      state.loading = true;
    },
    unsetLoading: state => {
      state.loading = false;
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      const selectedLanguage = action.payload.substr(0, 2); // Gets only the language (ex.: en-US / en-GB = en)
      state.language = selectedLanguage;
      localStorage.setItem(LocalStorageKeys.LANGUAGE, selectedLanguage);
    },
    addToast: (state, action: PayloadAction<ToastOptions>) => {
      toast(toastOptionsToContent(action.payload), {
        type: action.payload.type,
      });
    },
    setNavbarCollapsed: (state, action: PayloadAction<boolean>) => {
      state.navbarCollapsed = action.payload;
      const navbarCollapsed = JSON.stringify(action.payload);
      localStorage.setItem(LocalStorageKeys.NAVBAR_COLLAPSED, navbarCollapsed);
    },
  },
});

export const {
  setLoading,
  unsetLoading,
  setLanguage,
  addToast,
  setNavbarCollapsed,
} = slice.actions;

export default slice.reducer;
