export enum Pages {
  Home = 'Home',
  SignIn = 'Auth/SignIn',
  PasswordResetRequest = 'Auth/PasswordResetRequest',
  PasswordReset = 'Auth/PasswordReset',
  Users = 'Admin/Users',
  UserAddEdit = 'Admin/Users/UserAddEdit',
  Articles = 'Admin/Articles',
  ArticleAddEdit = 'Admin/Articles/ArticleAddEdit',
  Locations = 'Admin/Locations',
  LocationAddEdit = 'Admin/Locations/LocationAddEdit',
  Descriptions = 'Admin/Descriptions',
  DescriptionAddEdit = 'Admin/Descriptions/DescriptionAddEdit',
  ProjectDocuments = 'Projects/ProjectDocuments',
  ConfirmationsOverview = 'Confirmations/ConfirmationsOverview',
  OfferDetail = 'Projects/OfferDetail',
  Grips = 'Admin/Grips',
  GripAddEdit = 'Admin/Grips/GripAddEdit',
  EdgeColors = 'Admin/EdgeColors',
  EdgeColorAddEdit = 'Admin/EdgeColors/EdgeColorAddEdit',
  Models = 'Admin/Models',
  ModelsAddEdit = 'Admin/Models/ModelAddEdit',
  Colors = 'Admin/Colors',
  ColorAddEdit = 'Admin/Colors/ColorAddEdit',
  Catalogs = 'Admin/Catalogs',
  CatalogAddEdit = 'Admin/Catalogs/CatalogAddEdit',
  SubArticles = 'Admin/SubArticles',
  SubArticleAddEdit = 'Admin/SubArticles/SubArticleAddEdit',
  Alterations = 'Admin/Alterations',
  AlterationAddEdit = 'Admin/Alterations/AlterationAddEdit',
  SawArticles = 'Admin/SawArticles',
  SawArticleAddEdit = 'Admin/SawArticles/SawArticleAddEdit',
  PrintOffer = 'PrintOffer',
}
