export enum RoutePaths {
  SIGN_IN = '/signin',
  PASSWORD_CREATE = '/password-create',
  PASSWORD_RESET = '/password-reset',
  PROJECT_DOCUMENTS = '/projects/documents',
  CONFIRMATIONS_OVERVIEW = '/confirmations',
  OFFER_DETAIL = '/projects/offer',
  PRINT_OFFER_DETAIL = '/print-offer',
  USERS = '/admin/users',
  ARTICLES = '/admin/articles',
  LOCATIONS = '/admin/locations',
  DESCRIPTIONS = '/admin/descriptions',
  GRIPS = '/admin/grips',
  EDGECOLORS = '/admin/edge-colors',
  MODELS = '/admin/models',
  COLORS = '/admin/colors',
  CATALOGS = '/admin/catalogs',
  SUB_ARTICLES = '/admin/sub-articles',
  ALTERATIONS = '/admin/alterations',
  SAW_ARTICLES = '/admin/saw-articles',
}
