import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

const selectAppState = (state: RootState) => state.app;

export const selectIsLoading = createSelector(selectAppState, appState => appState.loading);

export const selectCurrentLanguage = createSelector(selectAppState, appState => appState.language);

export const selectNavbarCollapsed = createSelector(
  selectAppState,
  appState => appState.navbarCollapsed,
);
