import { Action, combineReducers, configureStore } from '@reduxjs/toolkit';
import counterReducer from 'components/Counter/CounterSlice';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory, History } from 'history';
import { useDispatch } from 'react-redux';
import { ThunkAction } from 'redux-thunk';
import alterationsReducer from 'store/alterations/AlterationsSlice';
import appReducer from 'store/app/AppSlice';
import articlesReducer from 'store/articles/ArticlesSlice';
import authReducer from 'store/auth/AuthSlice';
import catalogsReducer from 'store/catalogs/CatalogsSlice';
import colorsReducer from 'store/colors/ColorsSlice';
import confirmedOrdersReducer from 'store/confirmed-orders/ConfirmedOrdersSlice';
import customerAddressesReducer from 'store/customer-addresses/CustomerAddressesSlice';
import customersReducer from 'store/customers/CustomersSlice';
import descriptions from 'store/descriptions/DescriptionsSlice';
import edgeColorsReducer from 'store/edge-colors/EdgeColorsSlice';
import gripsReducer from 'store/grips/GripsSlice';
import locationsReducer from 'store/locations/LocationsSlice';
import modelsReducer from 'store/models/ModelsSlice';
import projectOffersReducer from 'store/project-offers/ProjectOffersSlice';
import projectsReducer from 'store/projects/ProjectsSlice';
import sawArticlesReducer from 'store/saw-articles/SawArticlesSlice';
import subArticlesReducer from 'store/sub-articles/SubArticlesSlice';
import usersReducer from 'store/users/UsersSlice';

export const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    app: appReducer,
    auth: authReducer,
    users: usersReducer,
    locations: locationsReducer,
    articles: articlesReducer,
    catalogs: catalogsReducer,
    projects: projectsReducer,
    projectOffers: projectOffersReducer,
    counter: counterReducer,
    descriptions: descriptions,
    customers: customersReducer,
    grips: gripsReducer,
    edgeColors: edgeColorsReducer,
    models: modelsReducer,
    colors: colorsReducer,
    sawArticles: sawArticlesReducer,
    subArticles: subArticlesReducer,
    alterations: alterationsReducer,
    customerAddresses: customerAddressesReducer,
    confirmedOrders: confirmedOrdersReducer,
  });

export const history = createBrowserHistory();

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: rootReducer(history),
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(routerMiddleware(history)),
});

export type RootState = ReturnType<ReturnType<typeof rootReducer>>;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
